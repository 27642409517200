import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Footer from '@components/layout/Footer/Footer';
import GlobalStyle from '@styles/globalStyles';
import BottomFixedButtons from '../Button/BottomFixedButtons';

const Header = dynamic(() => import('@components/layout/Header/Header'), { ssr: false });

type Props = {
  children: ReactNode;
};

const EXEPTION_HEADERS = [
  '/toon/event/sale',
  '/toon',
  '/toon/mypage/clip-book',
  '/toon/mypage/cart',
  '/toon/search',
  '/toon/new',
  '/toon/order/checkout',
  '/toon/brand/:id',
  '/toon/adult',
  '/toon/community',
  '/toon/users/login',
  '/toon/event/sale',
];
const EXEPTION_HEADERS_STARTHWITH = ['/toon/category', '/toon/brand', '/toon/community', '/toon/mypage/gift'];

EXEPTION_HEADERS.push(...EXEPTION_HEADERS.map(path => path.replace('/toon', '/game')));
EXEPTION_HEADERS_STARTHWITH.push(...EXEPTION_HEADERS_STARTHWITH.map(path => path.replace('/toon', '/game')));

export default function Layout({ children }: Props) {
  const { i18n } = useTranslation();
  const router = useRouter();
  const [isNewHeader, setIsNewHeader] = useState(false);
  useEffect(()=> {
    setIsNewHeader(EXEPTION_HEADERS.includes(router.pathname)
      || EXEPTION_HEADERS_STARTHWITH.some((path) => router.pathname.startsWith(path)));
  }, [router.pathname]);
  return (
    <>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore */}
      <GlobalStyle lang={i18n.language} />
      <Header isNewHeader={isNewHeader}/>
      {children}
      <Footer />
      <BottomFixedButtons />
    </>
  );
}
