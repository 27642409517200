import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { AutoNextImage, AutoNextImageContainer } from './styled';

const rangedWidth = (width) => {
  const responsive = [320, 600, 836, 1200, 1920];
  for (let v of responsive) {
    if (width <= v) return v;
  }
  return responsive[responsive.length - 1];
};

const normalizeSrc = (src) => {
  return src[0] === '/' ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality }) => {
  if (src.endsWith('.gif')) {
    //  gif 확장자의 경우 최적화 하지 않음
    return src;
  }
  return `https://www.acon3d.com/cdn-cgi/image/f=webp,w=${width},q=${quality || 85}/${normalizeSrc(src)}`;
};

export const AutoImage = (props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { width, style, src, unoptimized: unoptimizedFromProps, ...rest } = props;
  const [unoptimized, setUnoptimized] = useState(unoptimizedFromProps
    || src.startsWith('/imgs')
    || src.includes('.gif')
    || (!src.includes('.acon3d.com') && !src.includes('.cloudfront.net')));

  let timeout = null;
  useEffect(() => {
    if (!imgRef?.current || !props.checktimeout) return;
    const img = imgRef.current;
    timeout = setTimeout(function() {
      if (!img.complete) { // @ts-ignore
        props.onError && props.onError({ target: img });
        img.setAttribute('data-timedout', 'true');
      }
    }, 1000);
    return ()=> {
      clearTimeout(timeout);
    };
  }, [imgRef.current]);

  useEffect(()=> {
    if (unoptimizedFromProps === false
      || !src
      || (!src.includes('.acon3d.com') && !src.includes('.cloudfront.net'))
      || ['png', 'gif', 'jpg', 'jpeg'].some(ext => src.includes(`.${ext}`))) return;
    axios.head(src).then((res)=> {
      if (res.headers['content-type'] === 'image/gif') {
        setUnoptimized(true);
      }
    });
  }, [src]);

  const placeholder = props.src.startsWith('data:image') ? 'empty' : 'blur';

  const responsiveSizes = unoptimized ? {} : {
    srcSet: `${cloudflareLoader({ src: src, width: rangedWidth(parseInt(String(width), 10)), quality: props.quality || 85 })} 1x, `
      + `${cloudflareLoader({ src: src, width: rangedWidth(parseInt(String(width), 10) * 2), quality: props.quality || 85 })} 2x, `
      + `${cloudflareLoader({ src: src, width: rangedWidth(parseInt(String(width), 10) * 3), quality: props.quality || 85 })} 3x`,
  };

  const onErrorEnhanced = (event)=> {
    if (event.target?.srcset?.includes('cdn-cgi/image')) {
      event.target.srcset = '';
      return;
    }
    props?.onError && props.onError(event);
  };

  return (
    <AutoNextImageContainer style={style}>
      <AutoNextImage
        {...rest}
        ref={imgRef}
        // lazyBoundary={'600px'}
        placeholder={placeholder}
        // blurDataURL={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8vAkAAl4Bh+J6dBIAAAAASUVORK5CYII='}
        onError={onErrorEnhanced}
        onLoad={props.onLoad}
        // layout="fill"
        // objectFit="contain"
        // unoptimized={isError}
        {...responsiveSizes}
        src={src}
      />
    </AutoNextImageContainer>
  );
};
