import styled from 'styled-components';

export const BottomFixedButtonsContainer = styled.div`
  position: fixed;
  bottom: 35px;
  right: 35px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    right: 12px;
    bottom: 16px;
  }
`;
