import React, { useEffect } from 'react';
import { SnackbarContainer } from './Snackbar.styles';
import { Letter } from 'carpenstreet-designsystem';
import { useLayoutStore } from 'src/stores/layout/useLayout.store';
import { shallow } from 'zustand/shallow';

export default function Snackbar() {
  const [message, setMessage] = useLayoutStore((state) => [state.snackbarMessage, state.setSnackbarMessage], shallow);

  useEffect(() => {
    setTimeout(() => setMessage(null), 2000);
  }, []);

  return (
    <SnackbarContainer>
      <Letter variant="letter/en, ko/small" color={'color/white'}>
        {message}
      </Letter>
    </SnackbarContainer>
  );
}
