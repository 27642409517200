import ScrollTop from '@components/ScrollTop/ScrollTop';
import React, { useEffect, useState } from 'react';
import { useLayoutStore } from 'src/stores/layout/useLayout.store';
import { BottomFixedButtonsContainer } from './BottomFixedButtons.styles';
import { createPortal } from 'react-dom';
import StoreToggleButton from './StoreToggleButton';
import Snackbar from '../Snackbar/Snackbar';
import { shallow } from 'zustand/shallow';
import useResponsiveDevice from '@hooks/useResponsiveDevice';
import { useTranslation } from 'next-i18next';
import { LANGUAGE_CODE } from 'src/constants';

export default function BottomFixedButtons() {
  const { width } = useResponsiveDevice();
  const { i18n } = useTranslation();
  const [documentMounted, setDocumentMounted] = useState(false);
  const [isShowTopButton, snackbarMessage] = useLayoutStore((state) => [state.isShowTopButton, state.snackbarMessage], shallow);

  useEffect(() => setDocumentMounted(true), []);

  if (!documentMounted) return <div></div>;

  return createPortal(
    <>
      <BottomFixedButtonsContainer>
        {isShowTopButton && <ScrollTop />}
        {width <= 768 && [LANGUAGE_CODE.KO, LANGUAGE_CODE.EN].includes(i18n.language as LANGUAGE_CODE) && <StoreToggleButton />}
      </BottomFixedButtonsContainer>
      {snackbarMessage && <Snackbar />}
    </>,
    document.getElementById('__next'),
  );
}
