import {
  IJoinEvent,
  IProductClickEvent,
  IShareViewEvent,
  ISortOrderEvent,
  IFilterEvent,
  IScrapEvent,
  IAddCartFromScrapLog,
  IBuyCartLog,
  ICheckoutSuccessLog,
  IDirectBuyLog,
  IGNBClickLog,
} from './type';
import mixpanel from '.';

export const productClickEventLog = (property: IProductClickEvent) => {
  if (!property.pageName) return;

  mixpanel.event('Product Click', {
    'Page Name': property.pageName,
    'Action Name': property.actionName,
    'Product Name': property.productName,
    'Product No': property.productNo,
    ...(property?.groupTitle ? { 'Group Title': property?.groupTitle } : {}),
    ...(property?.groupSno ? { 'Group Sno': property?.groupSno } : {}),
    ...(property?.keyword ? { Keyword: property?.keyword } : {}),
    Description: `order_${property.order}`,
  });

  return true;
};

export const shareViewEventLog = (property: IShareViewEvent) => {
  if (!property.url) return;
  mixpanel.event('Share View', property);
};

export const productHistoryEventLog = (returnUrl: string) => {
  const url = new URL(window.location.origin + returnUrl);
  const mixpanelProperties: IJoinEvent = {};
  const urlParams = url?.search ? new URLSearchParams(url.search) : null;
  const splitUrl = (url.pathname?.split('/') || []).filter(Boolean);

  ['category', 'brand', 'product'].forEach((item) => {
    if (splitUrl.length === 2 && splitUrl[0] === item) {
      const valueKeyName = item === 'product' ? 'productNo' : 'categoryCode';
      mixpanelProperties.type = item;
      mixpanelProperties[valueKeyName] = splitUrl[1];
    }
  });

  if (splitUrl[0] === 'search' && urlParams?.get('keyword')) {
    mixpanelProperties.type = 'search';
    mixpanelProperties.keyword = urlParams.get('keyword');
  }

  if (Object.keys(mixpanelProperties).length > 0) mixpanel.event('Product History', mixpanelProperties);
};

export const filterEventLog = (props: IFilterEvent) => {
  props.minPrice = props.minPrice === 0 ? null : props.minPrice;
  props.maxPrice = props.maxPrice === 0 ? null : props.maxPrice;
  mixpanel.event('Use Filter', props);
};

export const sortOrderEventLog = (props: ISortOrderEvent) => {
  mixpanel.event('Sort Order', props);
};

export const ScrapLog = (props: IScrapEvent) => {
  mixpanel.event('Scrap', {
    'Product Nos': props.productNos,
    'Product Status': props.productStatus,
  });
};

// [todo]: Product Nos를 제외한 상품 번호 프로퍼티는 제거해야 함.
export const AddCartFromScrapLog = (props: IAddCartFromScrapLog) => {
  mixpanel.event('Add cart from scrap', {
    product_id: props.productNos.map((productNo) => productNo?.toString()),
    'Product Nos': props.productNos,
  });
};

export const BuyCartLog = (props: IBuyCartLog) => {
  mixpanel.event('Buy Cart', {
    'Discount amount': props.discountAmount,
    amount: props.amount,
    status: props.status,
    'Product Nos': props.productNos,
  });
};

// [todo]: Product Nos를 제외한 상품 번호 프로퍼티는 제거해야 함.
export const CheckoutSuccessLog = (props: ICheckoutSuccessLog) => {
  mixpanel.event(
    'Checkout',
    {
      state: 'success',
      orderNo: props.orderNo,
      amount: props.amount,
      'Acon Cash used': props.usedAconCash,
      'Point used': props.usedPoint,
      productNos: props.productNos.map((no) => no?.toString()),
      'Product Nos': props.productNos,
    },
    false,
  );
};

export const DirectBuyLog = (props: IDirectBuyLog) => {
  mixpanel.event('Direct Buy', {
    title: props.title,
    price: props.price,
    'discount rate': props.discountRate,
    brand: props.brand,
    extensions: props.extensions,
    'Number of reviews': props.reviewCount,
    'Number of views': props.viewCount,
    'Acon Only': props.isAconOnly,
    'Additional discount': props.isDiscount,
    'Product Nos': props.productNos,
  });
};

export const GNBClickLog = (props: IGNBClickLog) => {
  mixpanel.event('GNB Click', { name: props.name });
  mixpanel.event(`GNB Click_${props.name}`, { name: props.name });
};
