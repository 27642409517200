import styled from 'styled-components';
import { DEFAULT_MARGIN } from '@components/layout/Grid/Grid.constants';

export const ScrollTopContainer = styled.div<{ bannerHeight }>`
  width: 42px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.6);
  border: 0.5px solid #ffffff;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
  transition: bottom 0.5s ease-in-out;
  will-change: bottom;
`;
