import styled, { keyframes } from 'styled-components';
import { DEFAULT_MARGIN } from '@components/layout/Grid/Grid.constants';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const TitleWrapper = styled.div<{ theme }>(({ theme }) => {
  const { breakpoints } = theme;
  return {
    borderBottom: `1px solid ${(props) => props.theme.colors.cardGroupDivider}`,
    marginBottom: '8px',
    padding: '0px 5px 0px 0px',
    width: '100%',
    '&.grid-title': {
      marginInline: 'auto',
      maxWidth: '1444px',
      [breakpoints.up('lg')]: {
        padding: `0 ${DEFAULT_MARGIN.lg}px`,
      },
      [breakpoints.down('lg')]: {
        padding: `0 ${DEFAULT_MARGIN.lg}px`,
      },
      [breakpoints.down('md')]: {
        padding: `0 ${DEFAULT_MARGIN.md}px`,
      },
      [breakpoints.down('sm')]: {
        padding: `0 ${DEFAULT_MARGIN.sm}px`,
      },
    },
  };
});

export const TitleTop = styled.div`
  padding: 0px 0px 14px 0px;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleView = styled.span`
  cursor: pointer;
  position: relative;
  top: 4px;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
`;

export const TitleSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleSkeletonText = styled.div`
  width: 250px;
  height: 35px;
  background-image: linear-gradient(lightgray 178px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
`;

export const TitleSkeletonView = styled.div`
  width: 50px;
  height: 20px;
  background-image: linear-gradient(lightgray 178px, transparent 0);
  background-repeat: repeat-y;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
`;

export const TitleTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleSubText = styled.div<{ color: string; textColor: string; marginLeft: string }>`
  height: 30px;
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '28px')};

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 20px;
  padding: 8px 16px;

  color: ${(props) => (props.textColor ? props.textColor : '#fff')};

  ${(props) => `background-color: ${props.color};`}
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 14px;
    padding: 4px 12px;
    font-size: 12px;
    line-height: 16px;
  }
`;
