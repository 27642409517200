import styled from 'styled-components';

export const SnackbarContainer = styled.div`
  background-color: #313135;
  width: calc(100% - 24px);
  padding: 14px 16px;
  position: fixed;
  bottom: 16px;
  z-index: 101;
  left: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
