import { LANGUAGE_CODE } from 'src/constants';

/** PG 사 */
export enum PG_CODE {
  /** 엑심베이 정의 코드 */
  EXIMBAY = 'eximbay',
  /** 페이팔 정의 코드 */
  PAYPAL = 'paypal',
  /** 다우데이타 정의 코드 */
  DAOU = 'daou',
  /** 이니시스 정의 코드 */
  INICIS = 'html5_inicis',
}

/** 이니시스 - 결제 수단 */
export enum INICIS_METHOD {
  /** 신용카드 */
  CARD = 'card',
  /** 가상계좌 */
  VBANK = 'vbank',
}

/** 다우데이타 - 결제 수단 */
export enum DAOU_METHOD {
  /** 신용카드 */
  CARD = 'card',
  /** 가상계좌 */
  VBANK = 'vbank',
  /** 간편결제 */
  SIMPLE = 'simple',
}

/** 엑심베이 - 결제 수단 */
export enum EXIMBAY_METHOD {
  /** 위챗 */
  WECHAT = 'wechat',
  /** 알리페이 */
  ALIPAY = 'alipay',
  /** 해외카드 ( VISA/JCB/MASTER/AMEX ) */
  CARD = 'card',
  /** 유니온페이 */
  UNIONPAY = 'unionpay',
}

/** 공통 결제 수단 */
export enum COMMON_METHOD {
  /** 전액 할인 */
  FULL_DISCOUNT = 'fullDiscount',
}

/** 통화 화폐 단위 */
export enum CURRENCY {
  KRW = 'KRW', // 원화
  USD = 'USD', // 달러
  JPY = 'JPY', // 엔
  CNY = 'CNY', // 위안
}

/** 아임포트 - 언어 */
export const LANGUAGE = {
  [LANGUAGE_CODE.KO]: 'ko',
  [LANGUAGE_CODE.EN]: 'en',
  [LANGUAGE_CODE.CN]: 'zh',
  [LANGUAGE_CODE.JP]: 'jp',
};

/** 통화 화폐 단위 리스트 */
export const CURRENCY_LIST = {
  [LANGUAGE_CODE.KO]: CURRENCY.KRW,
  [LANGUAGE_CODE.EN]: CURRENCY.USD,
  [LANGUAGE_CODE.CN]: CURRENCY.CNY,
  [LANGUAGE_CODE.JP]: CURRENCY.JPY,
};

// 가맹점 코드
export const CODE = 'imp04119347';
export const CODE_STAGING = 'imp91379020';

// 몰별 결제수단
export const PAYMENT_METHOD = {
  [LANGUAGE_CODE.KO]: [
    {
      name: '간편 결제',
      payMethod: DAOU_METHOD.SIMPLE,
    },
    {
      name: '신용카드',
      payMethod: INICIS_METHOD.CARD,
    },
    {
      name: '가상계좌',
      payMethod: INICIS_METHOD.VBANK,
    },
  ],
  [LANGUAGE_CODE.CN]: [
    {
      name: '微信支付',
      payMethod: EXIMBAY_METHOD.WECHAT,
    },
    {
      name: '支付宝',
      payMethod: EXIMBAY_METHOD.ALIPAY,
    },
    {
      name: '银联',
      payMethod: EXIMBAY_METHOD.UNIONPAY,
    },
    {
      name: '信用卡',
      payMethod: EXIMBAY_METHOD.CARD,
    },
    {
      name: 'PayPal',
      payMethod: PG_CODE.PAYPAL,
    },
  ],
  /** EN / JA */
  [LANGUAGE_CODE.EN]: [
    {
      name: 'Paypal',
      payMethod: PG_CODE.PAYPAL,
    },
    {
      name: 'VISA/ MASTER/ JCB/ AMEX',
      payMethod: EXIMBAY_METHOD.CARD,
    },
  ],
};

export const PAYMENT_METHOD_I18N_KEY = {
  [PG_CODE.INICIS]: {
    [INICIS_METHOD.CARD]: 'payment.method.creditCard',
    [INICIS_METHOD.VBANK]: 'payment.method.virtualAccount',
  },
  [PG_CODE.EXIMBAY]: {
    [EXIMBAY_METHOD.CARD]: 'payment.method.visa',
    [EXIMBAY_METHOD.WECHAT]: 'payment.method.wechatpay',
    [EXIMBAY_METHOD.ALIPAY]: 'payment.method.alipay',
    [PG_CODE.PAYPAL]: 'payment.method.paypal',
    [EXIMBAY_METHOD.UNIONPAY]: 'payment.method.unionpay',
  },
};
