import styled from 'styled-components';

export const StoreToggleButtonContainer = styled.div<{ isOpen: boolean }>`
  background-color: #f5f5f6;
  border-radius: 21px;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

export const StoreToggleButtonItem = styled.div<{ isActive: boolean }>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    `
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  `};
`;
